<template>
<div class="row">
  <div class="col-12">
    <h5>{{ $t('add_recipient') }}</h5>
    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <div class="form-group">
        <label for="type">{{ $t('user_type') }}</label>
        <select v-model="form.type" id="type" class="form-control">
          <option value="INDIVIDUAL">Фіз. особа</option>
          <option value="COMPANY">Юр. особа</option>
          <option value="PRIVATE_ENTREPRENEUR">Фіз. особа-підприємець</option>
        </select>
      </div>
      <span v-if="form.type === 'INDIVIDUAL'">
        <div class="form-group">
          <label for="firstName">{{ $t('firstName') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" name="firstName" class="form-control" v-model="form.firstName" :class="{ 'is-invalid': form.errors.has('firstName') }">
          </div>
          <has-error :form="form" field="firstName" />
        </div>
        <div class="form-group">
          <label for="lastName">{{ $t('lastName') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" name="lastName" class="form-control" v-model="form.lastName" :class="{ 'is-invalid': form.errors.has('lastName') }">
          </div>
          <has-error :form="form" field="lastName" />
        </div>
        <div class="form-group">
          <label for="middleName">{{ $t('middleName') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" name="middleName" class="form-control" v-model="form.middleName" :class="{ 'is-invalid': form.errors.has('middleName') }">
          </div>
          <has-error :form="form" field="middleName" />
        </div>
      </span>
      <span v-if="form.type === 'PRIVATE_ENTREPRENEUR'">
        <div class="form-group">
          <label for="firstName">{{ $t('firstName') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" name="firstName" class="form-control" v-model="form.firstName" :class="{ 'is-invalid': form.errors.has('firstName') }">
          </div>
          <has-error :form="form" field="firstName" />
        </div>
        <div class="form-group">
          <label for="lastName">{{ $t('lastName') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" name="lastName" class="form-control" v-model="form.lastName" :class="{ 'is-invalid': form.errors.has('lastName') }">
          </div>
          <has-error :form="form" field="lastName" />
        </div>
        <div class="form-group">
          <label for="middleName">{{ $t('middleName') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" name="middleName" class="form-control" v-model="form.middleName" :class="{ 'is-invalid': form.errors.has('middleName') }">
          </div>
          <has-error :form="form" field="middleName" />
        </div>
        <div class="form-group">
          <label for="iban">{{ $t('iban') }}<span class="text-danger">*</span></label>
          <input type="text" name="iban" class="form-control" v-model="form.iban" :class="{ 'is-invalid': form.errors.has('iban') }">
          <has-error :form="form" field="iban" />
        </div>
        <div class="form-group">
          <label for="edrpou">{{ $t('edrpou') }}<span class="text-danger">*</span></label>
          <input type="text" name="edrpou" class="form-control" v-model="form.edrpou" :class="{ 'is-invalid': form.errors.has('edrpou') }">
          <has-error :form="form" field="edrpou" />
        </div>
        <div class="form-group">
          <label for="ipn">{{ $t('ipn') }}<span class="text-danger">*</span></label>
          <input type="text" name="ipn" class="form-control" v-model="form.ipn" :class="{ 'is-invalid': form.errors.has('ipn') }">
          <has-error :form="form" field="ipn" />
        </div>
      </span>
      <span v-if="form.type === 'COMPANY'">
        <div class="form-group">
          <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
          </div>
          <has-error :form="form" field="name" />
        </div>
        <div class="form-group">
          <label for="onFailReceiveType">Наложка на рахунок</label>
          <select v-model="form.cashOnDelivery" id="onFailReceiveType" class="form-control">
            <option :value="false">Ні</option>
            <option :value="true">Так</option>
          </select>
        </div>
        <div class="form-group">
          <label for="iban">{{ $t('iban') }}<span class="text-danger">*</span></label>
          <input type="text" name="iban" class="form-control" v-model="form.iban" :class="{ 'is-invalid': form.errors.has('iban') }">
          <has-error :form="form" field="iban" />
        </div>
        <div class="form-group">
          <label for="edrpou">{{ $t('edrpou') }}<span class="text-danger">*</span></label>
          <input type="text" name="edrpou" class="form-control" v-model="form.edrpou" :class="{ 'is-invalid': form.errors.has('edrpou') }">
          <has-error :form="form" field="edrpou" />
        </div>
        <div class="form-group">
          <label for="ipn">{{ $t('ipn') }}<span class="text-danger">*</span></label>
          <input type="text" name="ipn" class="form-control" v-model="form.ipn" :class="{ 'is-invalid': form.errors.has('ipn') }">
          <has-error :form="form" field="ipn" />
        </div>
      </span>
      <div class="form-group">
        <label for="phone">{{ $t('phone') }}<span class="text-danger">*</span></label>
        <input type="text" name="phone" class="form-control" v-mask="mask" v-model="form.phone" :class="{ 'is-invalid': form.errors.has('phone') }">
        <has-error :form="form" field="phone" />
      </div>
      <div class="form-group">
        <label for="postcode">{{ $t('postcode') }}<span class="text-danger">*</span></label>
        <div class="input-group">
          <input type="text" name="postcode" class="form-control" v-model="form.postcode" :class="{ 'is-invalid': form.errors.has('postcode') }">
        </div>
        <has-error :form="form" field="postcode" />
      </div>
      <div class="form-group">
        <label for="onFailReceiveType">{{ $t('parcel_not_pickup') }}</label>
        <select v-model="form.onFailReceiveType" id="onFailReceiveType" class="form-control">
          <option value="RETURN">Повернути відправнику</option>
          <option value="RETURN_AFTER_7_DAYS">Повернути після безкоштовного зберігання</option>
          <option value="PROCESS_AS_REFUSAL">Знищити відправлення</option>
        </select>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary" :disabled="form.busy">{{ $t('add') }}</button>
      </div>
    </form>
  </div>
  <table class="table table-sm">
    <tr>
      <th>#</th>
      <th>Дані</th>
      <th>За замовчуванням</th>
      <th></th>
    </tr>
    <tr v-for="(item, idx) in recipients" :key="item.id">
      <td>{{ idx + 1 }}</td>
      <td>
        <span v-if="status[idx] === idx">
          <p v-for="(v, i) in item.values" :key="i">
            <small class="text-info">{{ i }}: {{ v }}</small>
          </p>
        </span>
        <button class="btn btn-sm btn-outline-primary" @click="show(idx)" type="button">
          <span v-if="status[idx] === idx">{{ $t('hide') }}</span>
          <span v-else>{{ $t('show') }}</span>
        </button>
      </td>
      <td>
        <span v-if="item.default" class="badge badge-success">Активно</span>
        <span v-else class="badge badge-warning">Неактивно</span>
      </td>
      <td>
        <div class="btn-group">
          <button class="btn btn-sm btn-outline-success" type="button" @click="setStatus(item.id, item.default)">
            <fa v-if="item.default" icon="ban" />
            <fa v-if="!item.default" icon="check" />
          </button>
          <button class="btn btn-sm btn-danger" type="button" @click="remove(item.id)"><fa icon="trash" /></button>
        </div>
      </td>
    </tr>
  </table>
</div>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
const apiUrl = process.env.VUE_APP_ENDPOINT_V1
export default {
  props: {
    delivery: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    mask: ['38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    form: new Form({
      type: 'INDIVIDUAL',
      postcode: '',
      name: '',
      firstName: '',
      lastName: '',
      middleName: '',
      onFailReceiveType: 'RETURN',
      cashOnDelivery: false,
      phone: '',
      iban: '',
      edrpou: '',
      ipn: '',
      tin: ''
    }),
    recipients: null,
    status: []
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async create () {
      this.form.busy = true
      this.form.delivery_id = this.delivery.id
      await this.form.post(apiUrl + 'deliveryProvider').then(resp => {
        if (resp.data) {
          this.getData()
          this.form.busy = false
        }
      }).catch(error => {
        this.form.errors.set(error.response.data.error.errors)
      })
    },
    async remove (id) {
      await axios.delete(apiUrl + 'deliveryProvider/' + id)
      this.getData()
    },
    async setStatus (id, status) {
      const defaultStatus = !status
      await axios.post(apiUrl + 'deliveryProvider/default', { 'id': id, delivery: this.delivery.id, status: defaultStatus })
      this.getData()
    },
    async getData () {
      await axios.get(apiUrl + 'deliveryProvider/' + this.delivery.id).then(resp => {
        if (resp.data) {
          this.recipients = resp.data.map(item => {
            const value = JSON.parse(item.values)
            return {
              id: item.id,
              default: item.default,
              values: value
            }
          })
        }
      })
    },
    show (index) {
      if (typeof this.status[index] !== 'undefined') {
        this.status.splice(index, 1)
      } else {
        this.status.push(index)
      }
    }
  }
}
</script>
